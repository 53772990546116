import {
  AssigneeUser,
  ChangeAssigneeUser,
  ChatbotConfigurationVerification,
  ChatMessage,
  ChatSessionWithVerificationsResponse,
  ChatUser,
  ChatVerificationRequest,
  Collection,
  EditMessage,
  RequestedByUser,
  Span,
  Term
} from '../../../generated';

import { ChatSessionWithVerifications } from '../../models/chat-session-with-verifications.model';
import { ChatbotMessage as ChatbotMessageModel } from '../../models/chatbot-message';
import { MessageReferencedSpans, Term as TermModel } from '../../models/chatbot-response';
import { EditMessageModel } from '../../models/edit-message.model';
import { UserInfo } from '../../models/user-info.model';
import { VerificationStatus } from '../../models/verification-status.model';
import {
  VerificationRequest,
  VerificationRequest as VerificationRequestModel
} from '../../models/verification-request.model';
import { VerificationsChatTopicInfo } from '../../models/verifications-chat-topic-info.model';
import { Collection as CollectionModel } from '../../models/collection.model';
import { ChatbotConfigurationSession } from 'app/models/chatbot-configuration-session.model';

export class ChatSessionVerificationsMapper {
  public static mapToChatSessionWithVerifications(
    chatSessionWithVerificationsResponse: ChatSessionWithVerificationsResponse
  ): ChatSessionWithVerifications {
    return {
      chatId: chatSessionWithVerificationsResponse.chatId,
      title: chatSessionWithVerificationsResponse.title,
      active: chatSessionWithVerificationsResponse.active,
      startedAt: new Date(chatSessionWithVerificationsResponse.startedAt),
      chatHistory: this.mapToChatMessage(chatSessionWithVerificationsResponse.chatHistory),
      verificationRequests: this.mapToVerificationRequests(
        chatSessionWithVerificationsResponse.chatVerificationRequests
      ),
      user: this.mapChatUserToUserInfo(chatSessionWithVerificationsResponse.chatUser),
      collectionId: chatSessionWithVerificationsResponse.collectionId,
      chatbotConfiguration: this.mapToChatbotConfiguration(
        chatSessionWithVerificationsResponse.chatbotConfiguration
      )
    };
  }

  private static mapToChatMessage(chatHistory: ChatMessage[]): ChatbotMessageModel[] {
    if (!chatHistory) {
      return [];
    }
    return chatHistory.map(h => {
      return {
        messageId: h.chatMessageId,
        message: h.content,
        terms: this.mapToTerms(h.terms),
        referencedSpans: this.mapToReferenceSpans(h.referencedSpans),
        source: h.role == ChatMessage.RoleEnum.USER ? 'user' : 'bot',
        type: 'text',
        editMessage: this.mapEditMessageToEditMessageModel(h.edit)
      };
    });
  }

  private static mapToTerms(terms: Term[]): TermModel[] {
    if (!terms) {
      return [];
    }
    return terms?.map(t => {
      return {
        term: t.term,
        termId: t.termId,
        id: t.id,
        definition: t.definition
      };
    });
  }

  private static mapToReferenceSpans(referencedSpans: Span[]): MessageReferencedSpans[] {
    if (!referencedSpans) {
      return [];
    }
    return referencedSpans.map(s => {
      return {
        id: s.id,
        filename: s.filename,
        section: s.section,
        text: s.text
      };
    });
  }

  private static mapToVerificationRequests(
    requests: ChatVerificationRequest[]
  ): VerificationRequestModel[] {
    if (!requests) {
      return [];
    }
    return requests.map(r => {
      return {
        requestId: r.requestId,
        chatMessageId: r.chatMessageId,
        description: r.description,
        requestedBy: this.mapRequestedByUserToUserInfo(r.requestedBy),
        requestedOn: new Date(r.requestedOn),
        assignee: this.mapAssigneeUserToUserInfo(r.assignee),
        status: VerificationStatus[r.status],
        updatedAt: new Date(r.updatedAt)
      };
    });
  }

  private static mapRequestedByUserToUserInfo(requestedByUser: RequestedByUser): UserInfo {
    if (!requestedByUser) {
      return undefined;
    }
    return {
      userId: requestedByUser.userId,
      fullName: requestedByUser.userFullName,
      organization: {
        id: requestedByUser.requestedByUserOrganization.id,
        name: requestedByUser.requestedByUserOrganization.name
      },
      photoId: requestedByUser.userPhotoId
    };
  }

  private static mapAssigneeUserToUserInfo(assigneeUser: AssigneeUser): UserInfo {
    if (!assigneeUser) {
      return undefined;
    }
    return {
      userId: assigneeUser.userId,
      fullName: assigneeUser.userFullName,
      organization: {
        id: assigneeUser.assigneeUserOrganization.id,
        name: assigneeUser.assigneeUserOrganization.name
      },
      photoId: assigneeUser.userPhotoId
    };
  }

  private static mapChatUserToUserInfo(user: ChatUser): UserInfo {
    if (!user) {
      return undefined;
    }
    return {
      userId: user.userId,
      fullName: user.userFullName,
      organization: { id: user.chatUserOrganization.id, name: user.chatUserOrganization.name },
      photoId: user.userPhotoId
    };
  }

  public static mapChangeAssigneeUserToUserInfo(user: ChangeAssigneeUser): UserInfo {
    if (!user) {
      return undefined;
    }
    return {
      userId: user.id,
      fullName: user.fullName,
      organization: { id: user.organization.id, name: user.organization.name },
      photoId: user.photoId
    };
  }

  public static mapVerificationsChatTopicInfoToVerificationRequest(
    verificationsChatTopicInfo: VerificationsChatTopicInfo
  ): VerificationRequest {
    if (!verificationsChatTopicInfo) {
      return undefined;
    }
    return {
      chatMessageId: verificationsChatTopicInfo.chatMessageId,
      status: verificationsChatTopicInfo.verification.status,
      updatedAt: verificationsChatTopicInfo.verification.updatedAt,
      requestedBy: verificationsChatTopicInfo.verification.requestedBy,
      requestedOn: verificationsChatTopicInfo.verification.requestedOn,
      assignee: verificationsChatTopicInfo.verification.assignee,
      requestId: verificationsChatTopicInfo.verification.requestId,
      description: verificationsChatTopicInfo.verification.description
    };
  }

  public static mapEditMessageToEditMessageModel(editMessage: EditMessage): EditMessageModel {
    if (!editMessage) {
      return undefined;
    }
    return {
      editedContent: editMessage.editedContent,
      terms: editMessage.terms,
      referencedSpans: editMessage.referencedSpans,
      user: {
        userId: editMessage.user.userId,
        fullName: editMessage.user.userFullName,
        photoId: editMessage.user.userPhotoId
      }
    };
  }

  public static copyVerificationRequestValues(
    target: VerificationRequest,
    source: VerificationRequest
  ) {
    target.status = source.status;
    target.updatedAt = source.updatedAt;
    target.requestedBy = source.requestedBy;
    target.requestedOn = source.requestedOn;
    target.assignee = source.assignee;
    target.requestId = source.requestId;
    target.description = source.description;
  }

  public static mapToChatbotConfiguration(
    chatbotConfiguration: ChatbotConfigurationVerification
  ): ChatbotConfigurationSession {
    return {
      collections: this.mapToCollection(chatbotConfiguration.collections),
      photoId: chatbotConfiguration.photoId,
    };
  }

  public static mapToCollection(collections: Collection[]): CollectionModel[] {
    if (!collections) {
      return undefined;
    }
    return collections.map(c => {
      return {
        id: c.id,
        name: c.name
      };
    });
  }
}
