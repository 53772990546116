/**
 * chat-java-api - Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatUser } from './chatUser';
import { ChatMessage } from './chatMessage';
import { ChatVerificationRequest } from './chatVerificationRequest';
import { ChatbotConfigurationVerification } from './chatbotConfigurationVerification';


export interface ChatSessionWithVerificationsResponse { 
    chatId?: string;
    title?: string;
    active?: boolean;
    startedAt?: Date;
    chatHistory?: Array<ChatMessage>;
    chatUser?: ChatUser;
    chatVerificationRequests?: Array<ChatVerificationRequest>;
    collectionId?: string;
    chatbotConfiguration?: ChatbotConfigurationVerification;
}

