/**
 * chat-java-api - Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChangeStatusResponse { 
    requestId?: string;
    updatedAt?: Date;
    newStatus?: ChangeStatusResponse.NewStatusEnum;
}
export namespace ChangeStatusResponse {
    export type NewStatusEnum = 'REQUESTED' | 'IN_PROGRESS' | 'COMPLETED';
    export const NewStatusEnum = {
        REQUESTED: 'REQUESTED' as NewStatusEnum,
        IN_PROGRESS: 'IN_PROGRESS' as NewStatusEnum,
        COMPLETED: 'COMPLETED' as NewStatusEnum
    };
}


