import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WebCommonsModule } from '@apiax/web-commons';
import { TranslateModule } from '@ngx-translate/core';

import { ReferenceModalComponent } from 'shared/app/components/tooltips/reference-modal/reference-modal.component';
import { TaxonomyModalComponent } from 'shared/app/components/tooltips/taxonomy-modal/taxonomy-modal.component';
import { TooltipDirective } from 'shared/app/components/tooltips/tooltip.directive';
import { CollectionSelectorComponent } from './app/components/collection-selector/collection-selector.component';
import { SettingsModalComponent } from './app/components/settings-modal/settings-modal.component';
import { ResolveTermPipe } from './app/pipes/resolve-term.pipe';
import { SafeHtmlPipe } from './app/pipes/safe-html.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    ResolveTermPipe,
    TooltipDirective,
    ReferenceModalComponent,
    SettingsModalComponent,
    TaxonomyModalComponent,
    CollectionSelectorComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonModule,
    TranslateModule,
    MatSnackBarModule,
    MatTooltipModule,
    WebCommonsModule,
    MatIconModule
  ],
  exports: [SafeHtmlPipe, TooltipDirective, ResolveTermPipe, CollectionSelectorComponent]
})
export class SharedModule {}
