import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { MarkdownService } from 'ngx-markdown';

import { ReferenceModalComponent } from 'shared/app/components/tooltips/reference-modal/reference-modal.component';
import {
  TaxonomyModalComponent,
  TaxonomyModalInput
} from 'shared/app/components/tooltips/taxonomy-modal/taxonomy-modal.component';
import { ChatbotMessage } from '../../../../models/chatbot-message';
import { MessageReferencedSpans, Term } from '../../../../models/chatbot-response';
import { StoreChatBotReferences } from '../../../../domain/stores/chat/chat.action';
import { ChatState } from '../../../../domain/stores/chat/chat.state';
import { ThumbInformation } from 'app/chatbot/feedback/feedback';
import { SettingsState } from '../../../../domain/stores/settings/settings.state';

const TOOLTIP_WIDTH = 300;
const TOOLTIP_PADDING = 8;

interface LinkedReferences {
  section: string;
  referencedSpans: MessageReferencedSpans;
  hasWhiteSpaceToPrevious?: boolean;
}

@UntilDestroy()
@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  @Input()
  public taxonomyClickable = false;
  @Input()
  public message: ChatbotMessage;
  @Input()
  public showFeedback = true;

  private popoverListeners = new Subscription();

  constructor(
    private el: ElementRef,
    private dialog: MatDialog,
    private rendererReferences: Renderer2,
    private rendererTax: Renderer2,
    private store: Store,
    private translation: TranslateService,
    private markdownService: MarkdownService
  ) {
  }

  ngOnDestroy(): void {
    this.popoverListeners?.unsubscribe();
    this.onReferenceOut();
  }

  ngAfterViewInit(): void {
    let text = this.el.nativeElement.innerHTML;
    const shouldReplace = this.shouldReplace(text);
    const terms: Term[] = this.message.terms;

    //References
    if (shouldReplace.length > 0 || (terms && terms.length > 0)) {
      const message = cloneDeep(this.message);

      message.referencedSpans = message.referencedSpans.map(c => {
        c.text = this.markdownToHtml(c.text);
        return c;
      });

      shouldReplace.forEach((match, i) => {
        //Current
        const linkedReferences: LinkedReferences[] = [];
        const currentSection = JSON.parse(match.section_json);
        if (currentSection.filename && currentSection.section) {
          const referencedSpansToPush = message.referencedSpans.find(
            c =>
              this.parseSpecialCharacters(c.filename) ===
                this.parseSpecialCharacters(currentSection.filename) &&
              this.parseSpecialCharacters(c.section) ===
                this.parseSpecialCharacters(currentSection.section)
          );
          linkedReferences.push({
            section: `(linkto::${match.section_json})`,
            referencedSpans: {
              ...referencedSpansToPush,
              expanded: false,
              lastSentence: '',
              sections: []
            }
          });
        }

        //Next
        let nextIndex = i + 1;
        let link1End = match.index + `(linkto::${match.section_json})`.length;
        let link2Start = shouldReplace[nextIndex]?.index;
        let areLinked = this.areReferencesLinked(link1End, link2Start);
        while (areLinked.areLinked) {
          //Adding reference
          const nextSection = JSON.parse(shouldReplace[nextIndex].section_json);
          if (nextSection.filename && nextSection.section) {
            const referencedSpansToPush = message.referencedSpans.find(
              c =>
                this.parseSpecialCharacters(c.filename) ===
                  this.parseSpecialCharacters(nextSection.filename) &&
                this.parseSpecialCharacters(c.section) ===
                  this.parseSpecialCharacters(nextSection.section)
            );
            linkedReferences.push({
              section: `(linkto::${shouldReplace[nextIndex].section_json})`,
              referencedSpans: {
                ...referencedSpansToPush,
                expanded: false,
                lastSentence: '',
                sections: []
              },
              hasWhiteSpaceToPrevious: areLinked.whiteSpaceBetween
            });
          }
          //Update for next iteration
          link1End =
            shouldReplace[nextIndex].index +
            `(linkto::${shouldReplace[nextIndex].section_json})`.length;
          link2Start = shouldReplace[nextIndex + 1]?.index;
          nextIndex++;

          areLinked = this.areReferencesLinked(link1End, link2Start);
        }

        //Extract last sentence
        const subtext = text.substring(
          0,
          text.indexOf(this.combineReferencesBeforeParsing(linkedReferences))
        );
        let lastSentence: string;
        const reversedSubtext = this.reverseString(subtext);
        const regex = /\s\./;
        const matchTemp = regex.exec(reversedSubtext);
        if (matchTemp) {
          lastSentence = this.reverseString(reversedSubtext.substring(0, matchTemp.index)).trim();
        } else {
          lastSentence = subtext.trim();
        }
        lastSentence = lastSentence.replace(/<br>/g, '');
        lastSentence =
          lastSentence.length > 0 ? this.replaceQuotes(lastSentence) : this.replaceQuotes(subtext);
        lastSentence = this.replaceTaxonomyReferences(
          lastSentence,
          terms,
          message.messageId,
          false
        );

        //Storing the references in Store, this is done to avoid destroying strings with JSON.stringify
        if (text.indexOf(this.combineReferencesBeforeParsing(linkedReferences)) !== -1) {
          this.store.dispatch(
            new StoreChatBotReferences({
              referenceId: message.messageId + '_' + (i + 1),
              referencedSpans: this.groupReferencesByFilename(
                linkedReferences.map(r => r.referencedSpans)
              ),
              lastSentence: lastSentence
            })
          );
        }

        text = text.replace(
          this.combineReferencesBeforeParsing(linkedReferences),
          `<span class='section-reference' referencedSpans='${
            message.messageId + '_' + (i + 1)
          }'>[&quot;]</span>`
        );
      });

      const contentWithReferences = this.replaceTaxonomyReferences(
        text,
        terms,
        message.messageId,
        this.taxonomyClickable
      );
      text = contentWithReferences;
    }
    this.el.nativeElement.innerHTML = this.markdownService.parse(text, { disableSanitizer: true });
    this.toolTipEventHandler();
  }

  private replaceTaxonomyReferences(
    text: string,
    terms: Term[],
    messageId: string,
    clickable: boolean
  ): string {
    const regex2 = /\[([^\]]+)\]\(termId::([^)]+)\)/g;
    let match;
    let result = text;
    while ((match = regex2.exec(text)) !== null) {
      const term = terms.find(term => term.termId === match[2]);
      if (term) {
        if (clickable) {
          result = result.replace(
            match[0],
            `<span class='term' termId='${term.termId}' messageId='${messageId}'>${match[1]}</span>`
          );
        } else {
          result = result.replace(match[0], `<span termId='${term.termId}'>${match[1]}</span>`);
        }
      }
    }
    return result;
  }

  private areReferencesLinked(
    reference1Ending: number,
    reference2Starting: number
  ): { areLinked: boolean; whiteSpaceBetween: boolean } {
    //Adjusting for whitespace
    if (reference1Ending + 1 === reference2Starting) {
      return { areLinked: true, whiteSpaceBetween: true };
    } else if (reference1Ending === reference2Starting) {
      return { areLinked: true, whiteSpaceBetween: false };
    } else {
      return { areLinked: false, whiteSpaceBetween: false };
    }
  }

  private shouldReplace(
    text: string
  ): {
    index: number;
    section_json: string;
  }[] {
    const regex = /linkto::({.*?})/g;
    const matches: Array<{ index: number; section_json: string }> = [];
    let match;
    while ((match = regex.exec(text)) != null) {
      matches.push({ index: match.index, section_json: match[1] });
    }
    matches.sort((a, b) => a.index - b.index);
    return matches;
  }

  private toolTipEventHandler() {
    //References
    const sectionReferences = this.el.nativeElement.querySelectorAll('.section-reference');
    sectionReferences.forEach((sectionReference: HTMLElement) => {
      this.popoverListeners.add(
        this.rendererReferences.listen(sectionReference, 'mouseover', event => {
          this.onReferenceHover(event, sectionReference);
        })
      );
      this.popoverListeners.add(
        this.rendererReferences.listen(sectionReference, 'mouseout', () => {
          this.onReferenceOut();
        })
      );
      this.popoverListeners.add(
        this.rendererReferences.listen(sectionReference, 'click', () => {
          this.onReferenceClicked(sectionReference);
        })
      );
    });

    //Terms
    const terms = this.el.nativeElement.querySelectorAll('.term');
    terms.forEach((term: HTMLElement) => {
      this.popoverListeners.add(
        this.rendererTax.listen(term, 'mouseover', event => {
          this.onTermHovered(event, term);
        })
      );
      this.popoverListeners.add(
        this.rendererTax.listen(term, 'mouseout', () => {
          this.onTermOut();
        })
      );
      this.popoverListeners.add(
        this.rendererTax.listen(term, 'click', () => {
          this.onTermClicked(term);
        })
      );
    });
  }

  private onReferenceHover(event, htmlElement) {
    const isMobile = this.store.selectSnapshot(SettingsState.getIsMobileVersion);
    if (isMobile) {
      return;
    }
    const bodyX = document.body.clientWidth;
    const bodyY = document.body.clientHeight;
    let left, top;

    const referenceId = htmlElement.attributes.referencedSpans.value;
    const messageReference = this.store.selectSnapshot(ChatState.getMessageReferences).find(r => {
      return r.referenceId === referenceId;
    });

    if (messageReference.referencedSpans) {
      const chars = messageReference.referencedSpans.reduce((accLength, currentReferencedSpans) => {
        accLength += currentReferencedSpans.sections.reduce((accSection, currentSection) => {
          return accSection + (accLength < 500 ? currentSection?.text?.length : 0);
        }, 0);
        return accLength;
      }, 0);

      const xPos = event.clientX + TOOLTIP_WIDTH;
      if (xPos < bodyX) {
        left = 'left:' + (event.clientX + TOOLTIP_PADDING) + 'px;';
      } else {
        const adjustedX = event.clientX - (xPos - bodyX + 50);
        left = 'left:' + (adjustedX + TOOLTIP_PADDING) + 'px;';
      }

      const adaptedHeight = chars / 2 > 175 ? 200 : chars / 2 + 25;
      const yPos = event.clientY + adaptedHeight;
      if (yPos < bodyY) {
        top = 'top:' + event.clientY + 'px;';
      } else {
        const adjustedY = event.clientY - adaptedHeight;
        top = 'top:' + adjustedY + 'px;';
      }

      let displayCharCount = 0;
      let tooltipToBig = false;
      const tooltipText = this.translation.instant('DOCUMENTS_REFERENCES.TITLE');
      //The actual tooltip
      const tooltip = `
      <div id='tooltip' style='${left} ${top}' class='tooltip-wrapper'>
          <p class='tooltip-info-title'>${tooltipText}</p>
          ${messageReference.referencedSpans
            .map(contextCurrent => {
              displayCharCount += contextCurrent.filename.length;
              if (!tooltipToBig) {
                return `
                <div class='document-wrapper'>
                    <span class='tooltip-document'>${contextCurrent.filename}</span>
                    ${contextCurrent.sections
                      .map(section => {
                        displayCharCount += section.text.length;
                        displayCharCount += section.sectionName.length;
                        if (displayCharCount > 800 && !tooltipToBig) {
                          tooltipToBig = true;
                          return `
                           <span class='tooltip-text' id='tooltip-text'>(...)</span>
                        `;
                        } else if (!tooltipToBig) {
                          return `
                           <span class='tooltip-section'>${section.sectionName}</span>
                           <span class='tooltip-text' id='tooltip-text'>"${section.text}"</span>
                        `;
                        } else {
                          return '';
                        }
                      })
                      .join('')}
                </div>
              `;
              } else {
                return '';
              }
            })
            .join('')}
       </div>
      `;

      const elements = document.getElementsByClassName('tooltip-wrapper');
      if (elements.length > 0) {
        elements[0].remove(); // make sure that if there is already a callout, we remove it
      }

      document.body.insertAdjacentHTML('beforeend', tooltip); // append to body element
    }
  }

  private onReferenceOut() {
    const elements = document.getElementsByClassName('tooltip-wrapper');
    for (let i = elements.length - 1; i >= 0; --i) {
      elements[i].remove();
    }
  }

  private onReferenceClicked(htmlElement) {
    const referenceId = htmlElement.attributes.referencedSpans.value;
    const messageReference = this.store.selectSnapshot(ChatState.getMessageReferences).find(r => {
      return r.referenceId === referenceId;
    });

    messageReference.referencedSpans[0].lastSentence = this.getBackQuotes(
      messageReference.lastSentence
    );
    this.dialog.open(ReferenceModalComponent, {
      ...ReferenceModalComponent.DEFAULT_CONFIG,
      width: this.store.selectSnapshot(SettingsState.getIsMobileVersion) ? '95svw' : '50vw',
      maxWidth: this.store.selectSnapshot(SettingsState.getIsMobileVersion) ? '95svw' : '50vw',
      maxHeight: this.store.selectSnapshot(SettingsState.getIsMobileVersion) ? '95svh' : 'none',
      data: {
        referencedSpans: messageReference.referencedSpans,
        messageId: referenceId,
        showFeedback: this.showFeedback
      }
    });
  }

  private onTermClicked(htmlElement) {
    const attr = htmlElement.attributes;
    const term = this.store
      .selectSnapshot(ChatState.getChatBotMessages)
      .find(message => message.messageId === attr.messageId.value)
      ?.terms.find(term => term.termId === attr.termId.value);

    if (term) {
      this.dialog.open(TaxonomyModalComponent, {
        ...TaxonomyModalComponent.DEFAULT_CONFIG,
        width: this.store.selectSnapshot(SettingsState.getIsMobileVersion) ? '90svw' : '50vw',
        maxWidth: this.store.selectSnapshot(SettingsState.getIsMobileVersion) ? '95svw' : '50vw',
        maxHeight: this.store.selectSnapshot(SettingsState.getIsMobileVersion) ? '95svh' : 'none',
        data: {
          term: term
        } as TaxonomyModalInput
      });
    }
  }

  private onTermHovered(event, htmlElement) {
    const bodyX = document.body.clientWidth;
    const bodyY = document.body.clientHeight;
    let left, top;

    const attr = htmlElement.attributes;
    const term = this.message?.terms?.find(term => term.termId === attr.termId.value);

    if (term) {
      const chars = term.definition.length;

      const xPos = event.clientX + TOOLTIP_WIDTH;
      if (xPos < bodyX) {
        left = 'left:' + (event.clientX + TOOLTIP_PADDING) + 'px;';
      } else {
        const adjustedX = event.clientX - (xPos - bodyX + 50);
        left = 'left:' + (adjustedX + TOOLTIP_PADDING) + 'px;';
      }

      const adaptedHeight = chars / 2 > 175 ? 200 : chars / 2 + 75;
      const yPos = event.clientY + adaptedHeight;
      if (yPos < bodyY) {
        top = 'top:' + event.clientY + 'px;';
      } else {
        const adjustedY = event.clientY - adaptedHeight;
        top = 'top:' + adjustedY + 'px;';
      }

      const tooltip = `
        <div id='term-tooltip' class='term-wrapper' style='${left} ${top}'>
          <p class='term-title'>${term.term}</p>
          <p class='term-description'>${term.definition}</p>
        </div>
      `;

      const elements = document.getElementsByClassName('term-wrapper');
      if (elements.length > 0) {
        elements[0].remove(); // make sure that if there is already a callout, we remove it
      }

      document.body.insertAdjacentHTML('beforeend', tooltip); // append to body element
    }
  }

  private onTermOut() {
    const elements = document.getElementsByClassName('term-wrapper');
    for (let i = elements.length - 1; i >= 0; --i) {
      elements[i].remove();
    }
  }

  private markdownToHtml(inputText) {
    const markdownLinks = inputText.match(/\[\[([^\]]+)\]\]\(([^)]+)\)/g);

    if (markdownLinks) {
      markdownLinks.forEach(link => {
        const parts = /\[\[([^\]]+)\]\]\(([^)]+)\)/.exec(link);
        if (parts) {
          const [link, text, url] = parts;
          inputText = inputText.replace(link, `<a target='_blank' href='${url}'>${text}</a>`);
        }
      });
    }

    return inputText;
  }

  private groupReferencesByFilename(allReferencedSpans: MessageReferencedSpans[]) {
    return allReferencedSpans.reduce(
      (accumulator: MessageReferencedSpans[], currentReferencedSpans) => {
        const existingFile = accumulator.findIndex(
          referencedSpans => referencedSpans.filename === currentReferencedSpans.filename
        );
        if (existingFile !== -1) {
          accumulator[existingFile].sections.push({
            documentId: currentReferencedSpans.id,
            sectionName: currentReferencedSpans.section,
            text: currentReferencedSpans.text,
            feedback: {
              thumb: currentReferencedSpans.feedback?.thumb || ThumbInformation.NONE,
              comment: currentReferencedSpans.feedback?.comment || '',
              quickReply: currentReferencedSpans.feedback?.quickReply || ''
            }
          });
        } else {
          accumulator.push({
            ...currentReferencedSpans,
            sections: [
              {
                documentId: currentReferencedSpans.id,
                sectionName: currentReferencedSpans.section,
                text: currentReferencedSpans.text,
                feedback: {
                  thumb: currentReferencedSpans.feedback?.thumb || ThumbInformation.NONE,
                  comment: currentReferencedSpans.feedback?.comment || '',
                  quickReply: currentReferencedSpans.feedback?.quickReply || ''
                }
              }
            ]
          });
        }
        return accumulator;
      },
      []
    );
  }

  private combineReferencesBeforeParsing(linkedRef: LinkedReferences[]): string {
    let combined = '';
    for (let i = 0; i < linkedRef.length; i++) {
      combined += linkedRef[i].hasWhiteSpaceToPrevious
        ? ' ' + linkedRef[i].section
        : linkedRef[i].section;
    }
    return combined;
  }

  private reverseString(str) {
    return str.split('').reverse().join('');
  }

  private replaceQuotes(str) {
    str = str?.replace(/'/g, '&qoute;');
    return str;
  }

  private getBackQuotes(str) {
    str = str?.replace(/&qoute;/g, "'");
    return str;
  }

  private parseSpecialCharacters(str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');

    //Removing the white space
    const text = doc.documentElement.textContent.replace(/\s/g, '');
    return text.toLocaleLowerCase();
  }
}
