/**
 * chat-java-api - Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestedByUser } from './requestedByUser';
import { AssigneeUser } from './assigneeUser';


export interface ChatVerificationRequest { 
    requestId?: string;
    description?: string;
    chatMessageId?: string;
    requestedBy?: RequestedByUser;
    assignee?: AssigneeUser;
    requestedOn?: Date;
    updatedAt?: Date;
    status?: ChatVerificationRequest.StatusEnum;
}
export namespace ChatVerificationRequest {
    export type StatusEnum = 'REQUESTED' | 'IN_PROGRESS' | 'COMPLETED';
    export const StatusEnum = {
        REQUESTED: 'REQUESTED' as StatusEnum,
        IN_PROGRESS: 'IN_PROGRESS' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum
    };
}


