<div class="collection-buttons" *ngIf="collections.length > 0">
  <button
    *ngFor="let collection of collections"
    class="chip-action primary-chip"
    [ngClass]="{
      active: collection.id === selectedCollectionId || collection.id === highlightedCollection?.id
    }"
    [ngStyle]="{
      cursor: collection.id === selectedCollectionId ? 'initial' : 'pointer'
    }"
    [disabled]="isDisabled"
    (click)="onCollectionClicked(collection)"
  >
    {{ collection.name }}
  </button>
</div>
