import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TemplateModalComponent } from '@apiax/web-commons';

import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { Term } from '../../../../../models/chatbot-response';

export interface TaxonomyModalInput {
  term: Term;
}

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-modal',
  templateUrl: './taxonomy-modal.component.html',
  styleUrls: ['./taxonomy-modal.component.scss']
})
export class TaxonomyModalComponent implements OnInit {

  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG
  };

  taxonomyModalTitle = 'Taxonomy';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaxonomyModalInput,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.taxonomyModalTitle = this.translateService.instant('TAXONOMY_MODEL.TITLE');
  }

  public close() {
    this.dialog.closeAll();
  }
}
