/**
 * chat-java-api - Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatVerificationRequestBal } from './chatVerificationRequestBal';
import { ChatUser } from './chatUser';
import { ChatMessageBal } from './chatMessageBal';
import { ChatbotConfigurationBal } from './chatbotConfigurationBal';


export interface ChatSessionBalResponse { 
    chatId?: string;
    title?: string;
    startedAt?: Date;
    closedAt?: Date;
    chatHistory?: Array<ChatMessageBal>;
    chatUser?: ChatUser;
    chatVerificationRequests?: Array<ChatVerificationRequestBal>;
    collectionId?: string;
    chatbotConfiguration?: ChatbotConfigurationBal;
}

