import { Pipe, PipeTransform } from '@angular/core';
import { Term } from '../../../models/chatbot-response';
import { ResolveTermService } from '../services/resolve-term.service';

@Pipe({
  name: 'resolveTerm'
})
export class ResolveTermPipe implements PipeTransform {
  constructor(private resolveTermService: ResolveTermService) {}

  transform(value: string, terms: Term[] = [], field: 'term' | 'definition' = 'term'): string {
    return this.resolveTermService.resolveValue(value, terms, field);
  }
}
