import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TemplateModalComponent } from '@apiax/web-commons';

import { TranslateService } from '@ngx-translate/core';

export interface AddCommentModalOutput {
  comment: string;
  quickReply: string;
}

@Component({
  selector: 'app-add-comment-modal',
  templateUrl: './add-comment-modal.component.html',
  styleUrls: ['./add-comment-modal.component.scss']
})
export class AddCommentModalComponent implements OnInit {

  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG
  };

  commentControl: UntypedFormControl = new UntypedFormControl('');
  quickReplyEnabled = false;
  quickReplies: string[] = [
    'INCOMPLETE_ANSWER',
    'WRONG_ANSWER',
    'HALLUCINATING',
    'UNEXPECTEDLY_NO_ANSWER',
    'WRONG_REFERENCES'
  ];
  quickReply = '';
  addCommentTitle = 'Add Comment';

  constructor(
    public dialogRef: MatDialogRef<AddCommentModalComponent>,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.addCommentTitle = this.translateService.instant('FEEDBACK.TITLE');
  }

  onSave() {
    this.dialogRef.close({
      comment: this.commentControl.value,
      quickReply: this.quickReply
    });
  }

  quickReplyClicked(option: string) {
    this.quickReply = option;
  }

}
