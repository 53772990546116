import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChatTaxonomyApiService, ChatTaxonomyQuestionAnsweringRequest } from 'generated';

import { getConfig } from 'shared/app';
import { ChatbotMessage } from '../../../models/chatbot-message';
import { ChatbotResponse } from '../../../models/chatbot-response';
import { ErrorTranslationService } from './error-translation.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private httpClient: HttpClient,
    private errorTranslate: ErrorTranslationService,
    private chatTaxonomyApiService: ChatTaxonomyApiService
  ) {}

  startConversation(
    language: string,
    context: string,
    model: string,
    chatbotConfigurationId: string,
    starterMessage: string,
    collectionId: string
  ): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { language, context, model, chatbotConfigurationId, starterMessage, collectionId };

    return this.httpClient.post<ChatbotResponse>(apiUrl + 'start', data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateError(error);
      })
    );
  }

  sendMessage(message: string, chatId: string | null): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { message };
    if (chatId != null) {
      data['chatId'] = chatId;
    }
    return this.httpClient.post<ChatbotResponse>(apiUrl, data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateError(error);
      })
    );
  }

  sendTaxonomyMessage(message: string, chatId: string | null): Observable<ChatbotResponse> {
    const request: ChatTaxonomyQuestionAnsweringRequest = {
      message: message,
      chatId: chatId
    };
    return this.chatTaxonomyApiService.responseTaxonomyChat(request).pipe(
      map(response => {
        const chatbotResponse: ChatbotResponse = {
          chatId: chatId,
          message: response.message,
          messageId: response.messageId,
          type: 'text',
          verifiable: response.verifiable
        };
        return chatbotResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateError(error);
      })
    );
  }

  sendRulesMessage(message: ChatbotMessage, chatId: string | null): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { message: message.message, regprop: message.regprop };
    if (chatId != null) {
      data['chatId'] = chatId;
    }
    return this.httpClient.post<ChatbotResponse>(apiUrl + 'rules', data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateError(error);
      })
    );
  }

  sendCountryManualsMessage(message: string, chatId: string | null): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { message };
    if (chatId != null) {
      data['chatId'] = chatId;
    }
    return this.httpClient.post<ChatbotResponse>(apiUrl + 'country-manuals', data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateError(error);
      })
    );
  }

  updateSessionTitle(chatId: string, message: string, isoCode: string): Observable<string> {
    const apiUrl = this.sharedApiUrl();
    const data = { chatId, message, isoCode };
    return this.httpClient.post<string>(apiUrl + 'update-session-title', data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.errorTranslate.translateError(error);
      }),
      map((response: string) => {
        return response;
      })
    );
  }

  private apiUrl(): string {
    return `${getConfig('apiUrl')}/chat/`;
  }

  private javaApiUrl(): string {
    return `${getConfig('apiJavaUrl')}/api/chat/`;
  }

  private sharedApiUrl(): string {
    return `${getConfig('apiUrl')}/shared/`;
  }
}
