<apx-template-modal [data]='{ title: settingTitle }' [template]='modalTemplate'>
</apx-template-modal>

<ng-template #modalTemplate>
  <mat-dialog-content class='controls'>

    <mat-form-field *ngIf='isAnnotationUser() | async'>
      <mat-label>Feedback Mode</mat-label>
      <mat-select [(value)]='testerMode'>
        <mat-option [value]=true>On</mat-option>
        <mat-option [value]=false>Off</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'SETTINGS.LANGUAGE' | translate }}</mat-label>
      <mat-select [(value)]='initialLanguage' [disabled]='chatStarted'>
        <ng-template [ngForOf]='languages' let-option ngFor>
          <mat-option [disabled]='chatStarted' [value]='option.language'>{{ option.viewLanguage }}</mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="{{ contexts.length === 1 || chatStarted ? 'disabled-form-field' : '' }}">
      <mat-label>{{ 'SETTINGS.CONTEXT' | translate }}</mat-label>
      <mat-select disabled='{{ contexts.length === 1 || chatStarted }}' [(value)]='context'>
        <ng-template [ngForOf]='contexts' let-context ngFor>
          <mat-option disabled='{{ contexts.length === 1 || chatStarted }}' [value]='context'>{{ context }}</mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'SETTINGS.MODEL' | translate }}</mat-label>
      <mat-select disabled='{{ models.length === 1 || chatStarted }}' [(value)]='model'>
        <ng-template [ngForOf]='models' let-model ngFor>
          <mat-option disabled='{{ chatStarted }}' [value]='model.id'>{{ model.name }}</mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class='btn-action primary-action' (click)='save()'>
      {{ 'SETTINGS.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
