import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Collection } from 'app/models/collection.model';
import {
  IsMobileVersion,
  SetAvailableCollections,
  SetAvailableContext,
  SetAvailableLanguages,
  SetAvailableModels,
  SetChatLanguage,
  SetSelectedContext,
  SetSelectedModel,
  SetShowJumpingDots,
  SetTesterMode,
  SetUserInputDisabled,
  StoreChatbotConfigurationId,
  StoreCollectionId
} from './settings.action';

export interface SettingsStateModel {
  chatbotConfigurationId: string;
  userInputDisabled: boolean;
  showJumpingDots: boolean;
  chatLanguage: string;
  availableLanguages: { language: string; viewLanguage: string }[];
  availableContexts: string[];
  availableModels: { id: string; name: string }[];
  availableCollections: Collection[];
  switchingChatAvailable: boolean;
  selectedContext: string;
  selectedModel: string;
  isTesterMode: boolean;
  isMobileVersion: boolean;
  collectionId: string;
}

export const DEFAULT_STATE: SettingsStateModel = {
  chatbotConfigurationId: null,
  userInputDisabled: false,
  showJumpingDots: false,
  chatLanguage: 'en-US',
  availableLanguages: [],
  availableContexts: [],
  availableModels: [],
  availableCollections: [],
  switchingChatAvailable: false,
  selectedContext: null,
  selectedModel: null,
  isTesterMode: false,
  isMobileVersion: false,
  collectionId: null
};


@State<SettingsStateModel>({
  name: 'settings',
  defaults: DEFAULT_STATE
})
@Injectable()
export class SettingsState {
  @Selector()
  public static getUserInputDisabled(state: SettingsStateModel): boolean {
    return state.userInputDisabled;
  }

  @Selector()
  public static getShowJumpingDots(state: SettingsStateModel): boolean {
    return state.showJumpingDots;
  }

  @Selector()
  public static getChatLanguage(state: SettingsStateModel): string {
    return state.chatLanguage;
  }

  @Selector()
  public static getAvailableLanguages(
    state: SettingsStateModel
  ): { language: string; viewLanguage: string }[] {
    return state.availableLanguages;
  }

  @Selector()
  public static getAvailableContexts(state: SettingsStateModel): string[] {
    return state.availableContexts;
  }

  @Selector()
  public static getSelectedContext(state: SettingsStateModel): string {
    return state.selectedContext;
  }

  @Selector()
  public static getAvailableModels(state: SettingsStateModel): { id: string; name: string }[] {
    return state.availableModels;
  }

  @Selector()
  public static getAvailableCollections(state: SettingsStateModel): Collection[] {
    return state.availableCollections;
  }

  @Selector()
  public static getSelectedModel(state: SettingsStateModel): string {
    return state.selectedModel;
  }

  @Selector()
  public static getIsTesterMode(state: SettingsStateModel): boolean {
    return state.isTesterMode;
  }

  @Selector()
  public static getIsMobileVersion(state: SettingsStateModel): boolean {
    return state.isMobileVersion;
  }

  @Selector()
  public static getChatbotConfigurationId(state: SettingsStateModel): string {
    return state.chatbotConfigurationId;
  }

  @Selector()
  public static getCollectionId(state: SettingsStateModel): string {
    return state.collectionId;
  }

  @Action(SetUserInputDisabled)
  public setUserInputDisabled(
    context: StateContext<SettingsStateModel>,
    action: SetUserInputDisabled
  ): void {
    context.patchState({
      userInputDisabled: action.payload
    });
  }

  @Action(SetShowJumpingDots)
  public setShowJumpingDots(
    context: StateContext<SettingsStateModel>,
    action: SetShowJumpingDots
  ): void {
    context.patchState({
      showJumpingDots: action.payload
    });
  }

  @Action(SetAvailableLanguages)
  public setAvailableLanguages(
    context: StateContext<SettingsStateModel>,
    action: SetAvailableLanguages
  ): void {
    context.patchState({
      availableLanguages: action.payload
    });
  }

  @Action(SetAvailableCollections)
  public setAvailableCollections(
    context: StateContext<SettingsStateModel>,
    action: SetAvailableCollections
  ): void {
    context.patchState({
      availableCollections: action.payload
    });
  }

  @Action(SetSelectedContext)
  public setSelectedContext(
    context: StateContext<SettingsStateModel>,
    action: SetSelectedContext
  ): void {
    context.patchState({
      selectedContext: action.payload
    });
  }

  @Action(SetAvailableModels)
  public setAvailableModels(
    context: StateContext<SettingsStateModel>,
    action: SetAvailableModels
  ): void {
    context.patchState({
      availableModels: action.payload
    });
  }

  @Action(SetSelectedModel)
  public setSelectedModel(
    context: StateContext<SettingsStateModel>,
    action: SetSelectedModel
  ): void {
    context.patchState({
      selectedModel: action.payload
    });
  }

  @Action(SetAvailableContext)
  public setAvailableContext(
    context: StateContext<SettingsStateModel>,
    action: SetAvailableContext
  ): void {
    context.patchState({
      availableContexts: action.payload
    });
  }

  @Action(SetChatLanguage)
  public setChatLanguage(context: StateContext<SettingsStateModel>, action: SetChatLanguage): void {
    context.patchState({
      chatLanguage: action.payload
    });
  }

  @Action(SetTesterMode)
  public setTesterMode(context: StateContext<SettingsStateModel>, action: SetTesterMode): void {
    context.patchState({
      isTesterMode: action.payload
    });
  }

  @Action(IsMobileVersion)
  public isMobileVersion(context: StateContext<SettingsStateModel>, action: IsMobileVersion): void {
    context.patchState({
      isMobileVersion: action.payload
    });
  }

  @Action(StoreChatbotConfigurationId)
  public storeChatConfigurationId(
    context: StateContext<SettingsStateModel>,
    action: StoreChatbotConfigurationId
  ): void {
    context.patchState({
      chatbotConfigurationId: action.payload
    });
  }

  @Action(StoreCollectionId)
  public storeCollectionId(
    context: StateContext<SettingsStateModel>,
    action: StoreCollectionId
  ): void {
    context.patchState({
      collectionId: action.payload
    });
  }
}
