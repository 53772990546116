import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationService, TemplateModalComponent } from '@apiax/web-commons';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ThumbInformation } from 'app/chatbot/feedback/feedback';

import { StoreFeedbackReference } from '../../../../../domain/stores/chat/chat.action';
import { MessageReferencedSpans } from '../../../../../models/chatbot-response';
import {
  AddCommentModalComponent,
  AddCommentModalOutput
} from '../../../../../chatbot/feedback/add-comment-modal/add-comment-modal.component';
import { ChatPreviewState } from '../../../../../domain/stores/chat-preview/chat-preview.state';
import { ChatState } from '../../../../../domain/stores/chat/chat.state';
import { filter } from 'rxjs/operators';

export interface ReferenceModelData {
  referencedSpans: MessageReferencedSpans[];
  messageId: string;
  showFeedback: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-reference-modal',
  templateUrl: './reference-modal.component.html',
  styleUrls: ['./reference-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferenceModalComponent implements OnInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG
  };

  referenceTitle = 'Reference';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReferenceModelData,
    private dialog: MatDialog,
    private store: Store,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {
    data.referencedSpans[0].expanded = true;
  }

  ngOnInit(): void {
    this.referenceTitle = this.translateService.instant('DOCUMENTS_REFERENCES.TITLE');
  }

  onOpenAddCommentModal(
    referencedSpansIndex: number,
    sectionIndex: number,
    wasOpenedByThumbDown = false
  ) {
    if (this.checkChatActive()) {
      const dialogRef = this.dialog.open(AddCommentModalComponent, {
        ...AddCommentModalComponent.DEFAULT_CONFIG,
        data: {
          comment: this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback
            .comment,
          quickReply: this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex]
            .feedback.quickReply
        }
      });

      dialogRef.componentInstance.commentControl.setValue(
        this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback.comment
      );
      dialogRef.componentInstance.quickReplyEnabled = wasOpenedByThumbDown;
      dialogRef.componentInstance.quickReply = this.data.referencedSpans[
        referencedSpansIndex
      ].sections[sectionIndex].feedback.quickReply;

      dialogRef
        .afterClosed()
        .pipe(
          untilDestroyed(this),
          filter(result => result),
          filter(() => {
            return this.checkChatActive();
          })
        )
        .subscribe((result: AddCommentModalOutput) => {
          this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback.comment =
            result.comment;
          this.data.referencedSpans[referencedSpansIndex].sections[
            sectionIndex
          ].feedback.quickReply = result.quickReply;
          this.saveFeedback(referencedSpansIndex, sectionIndex);
        });
    }
  }

  onThumbUpClick(referencedSpansIndex: number, sectionIndex: number) {
    if (this.checkChatActive()) {
      this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback.thumb =
        ThumbInformation.UP;
      this.saveFeedback(referencedSpansIndex, sectionIndex);
    }
  }

  onThumbDownClick(referencedSpansIndex: number, sectionIndex: number) {
    if (this.checkChatActive()) {
      this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback.thumb =
        ThumbInformation.DOWN;
      this.saveFeedback(referencedSpansIndex, sectionIndex);
      this.onOpenAddCommentModal(referencedSpansIndex, sectionIndex, true);
    }
  }

  saveFeedback(referencedSpansIndex: number, sectionIndex: number) {
    this.store.dispatch(
      new StoreFeedbackReference({
        feedback: this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback,
        messageId: this.data.messageId,
        documentid: this.data.referencedSpans[referencedSpansIndex].sections[sectionIndex]
          .documentId
      })
    );
  }

  checkChatActive() {
    const chatId = this.store.selectSnapshot(ChatState.chatId);
    const active = this.store
      .selectSnapshot(ChatPreviewState.chatPreviews)
      .find(p => p.id === chatId)?.active;

    if (!active) {
      this.notificationService.showSimpleAlert(
        this.translateService.instant('INACTIVITY_INFO.NOT_ACTIVE_CANNOT_PERFORM_ACTION'),
        'error'
      );
    }
    return active;
  }
}
