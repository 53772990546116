import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TemplateModalComponent } from '@apiax/web-commons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChatState } from '../../../../domain/stores/chat/chat.state';
import { SettingsState } from '../../../../domain/stores/settings/settings.state';
import { AuthorizationService } from 'shared/core';

import { SetChatLanguage, SetSelectedContext, SetSelectedModel, SetTesterMode } from '../../../../domain/stores/settings/settings.action';

export interface SettingsModalInput {
  language: string;
}

@UntilDestroy()
@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit {

  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    minWidth: '400px'
  };

  languages: { language: string; viewLanguage: string }[];
  initialLanguage: string;
  chatStarted: boolean;
  contexts: string[];
  models: { id: string; name: string }[];
  context: string;
  model: string;
  testerMode: boolean;
  settingTitle = 'Settings';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SettingsModalInput,
    private dialog: MatDialog,
    private store: Store,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService
  ) {
    this.languages = this.store.selectSnapshot(SettingsState.getAvailableLanguages);
    this.initialLanguage = data.language;
    this.chatStarted = this.store.selectSnapshot(ChatState.chatId) !== null;
    this.contexts = this.store.selectSnapshot(SettingsState.getAvailableContexts);
    this.context = this.store.selectSnapshot(SettingsState.getSelectedContext);
    this.models = this.store.selectSnapshot(SettingsState.getAvailableModels);
    this.model = this.store.selectSnapshot(SettingsState.getSelectedModel);
    this.testerMode = this.store.selectSnapshot(SettingsState.getIsTesterMode);
  }

  ngOnInit() {
    this.settingTitle = this.translateService.instant('SETTINGS.TITLE');
  }

  public save() {
    localStorage.setItem('language', this.initialLanguage);
    this.translateService.use(this.initialLanguage.substring(0, 2));
    this.store.dispatch(new SetChatLanguage(this.initialLanguage));

    localStorage.setItem('context', this.context);
    this.store.dispatch(new SetSelectedContext(this.context));

    localStorage.setItem('model', this.model);
    this.store.dispatch(new SetSelectedModel(this.model));

    localStorage.setItem('testerMode', this.testerMode.toString());
    this.store.dispatch(new SetTesterMode(this.testerMode));

    this.dialog.closeAll();
  }

  toggleTesterMode() {
    this.testerMode = !this.testerMode;
  }

  isAnnotationUser(): Observable<boolean> {
    return this.authorizationService.currentUser.pipe(
      map(user => {
        const allowed_users = [
          'peter.bouda@apiax.com',
          'duarte.balata@apiax.com',
          'ilayda.gundogmus@apiax.com',
          'lorenz.koehl@apiax.com',
          'lukas.weichselbraun@apiax.com',
          'thomas.schaeubli@apiax.com',
          'christina.frein@apiax.com',
          'angelica.rainelli@apiax.com',
          'zoe.nicholas@apiax.com',
          'catarina.correia@apiax.com'
        ];
        return allowed_users.includes(user.email);
      })
    );
  }

}
