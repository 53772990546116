export enum ThumbInformation {
  UP = 'up',
  DOWN = 'down',
  NONE = ''
}

export interface Feedback {
  thumb: ThumbInformation;
  comment: string;
  quickReply: string;
}
