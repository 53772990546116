import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { AppService, AuthorizationService } from 'shared/core/services';

export * from './activity-allowed.guard';
export * from './auth.guard';
export * from './loading.guard';

export const LoadingGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appService = inject(AppService);

  return appService.loadData();
};

export const ActivityAllowedGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authorizationService = inject(AuthorizationService);

  if (route.data.hasOwnProperty('activities')) {
    return authorizationService.isAllowed(route.data.activities);
  }
  return true;
};
