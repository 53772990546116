import { Injectable } from '@angular/core';
import { Term } from '../../../models/chatbot-response';

@Injectable({
  providedIn: 'root'
})
export class ResolveTermService {
  public resolveValue(value: string, terms: Term[] = [], field: 'term' | 'definition' = 'term') {
    let adaptedValue = this.adaptReferenceValue(value);
    adaptedValue = adaptedValue?.split(':')[0];

    let result: string;
    const label = terms.find(l => l.termId === adaptedValue);
    if (label == null) {
      result = value;
    } else {
      result = label ? label[field] : value;
    }

    return this.replaceReferences(result, terms);
  }

  private adaptReferenceValue(value: string) {
    return value
      ?.replace(/:description/g, ':definition')
      .replace(/:title/g, ':term')
      .replace(/#\w\w\w/, '');
  }

  public replaceReferences(content: string, terms: Term[] = []): string {
    content = content?.replace(/:description/g, ':definition');
    for (let taxonomyEntry: RegExpExecArray; (taxonomyEntry = this.regExp.exec(content)); ) {
      const ref = taxonomyEntry[0];
      const separatorIndex = ref.indexOf(':');
      const termId = ref.substring(2, separatorIndex === -1 ? ref.length - 2 : separatorIndex);
      const entryField = ref.indexOf(':definition') > -1 ? 'definition' : 'term';

      const resolvedValue = this.resolveValue(termId, terms, entryField);
      content = content.replace(ref, resolvedValue);
    }

    return content;
  }

  private get regExp(): RegExp {
    return new RegExp(`\\[\\[.*?]]`, 'g');
  }
}
